function recaptchaCallback() {
    $('#f_recaptcha').valid();
}

$(document).ready(function(){

    $.validator.addMethod("validateRecaptcha", function (value, element) {
      if (grecaptcha.getResponse() == '') {
          return false;
      } else {
          return true;
      }
    }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
      if ($('#container_form form').length) {
          $('#contactRgpd').rules('add', {
                validateCgv: true
            });
          $('#f_recaptcha').rules('add', {
              validateRecaptcha: true
          });
        }
    }, 100);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });
    $.validator.addMethod("validateCgv", function (value, element) {
            return $('#contactRgpd').is(':checked');
        }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#container_form form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
                console.log(element);
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.form-group');
                error.addClass("checkbox");
                parentElm.append(error);
            } else if (element.attr('type') === undefined) {
                var parentElm = element.closest('.form-group');
                error.addClass("select");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

    // Iframe
    $('.wysiwyg iframe').wrap('<div class="resp-iframe"></div>');

    // Menu mobile //

    $('.toggleMenu').click(function() {
        $(this).toggleClass('active');
        $('.mainMenu-mobile').toggleClass('active');
        $('html').toggleClass('active');
    });

    $('.mainMenu-itemMobile').click(function() {
        $(this).find('.mainMenu-subMenu').slideToggle("slow");
    });

    // Slider citations //

    $('.owl-quotes').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        smartSpeed: 1000,
        items: 1,
        margin: 15,
    });

    $('.owl-destroyedQuote').owlCarousel({
        loop: false,
        nav: false,
        dots: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        items: 1,
    });

    // Bloc diaporama //

    $('.owl-slider').owlCarousel({
        loop: true,
        nav: false,
        dots: false,
        smartSpeed: 1000,
        items: 1,
        margin: 15,
        responsiveClass: true,
        responsive: {
            0:{
               dots: true,
            },
            600:{
                dots: false,
            },
            991:{
                dots: false,
            }
        }
    });

    $('.owl-destroyedSlider').owlCarousel({
        loop: false,
        nav: false,
        dots: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        items: 1,
    });

    $('.owl-next').click(function () {
        $('.owl-slider').trigger('next.owl.carousel');
    });

    $('.owl-prev').click(function () {
        $('.owl-slider').trigger('prev.owl.carousel');
    });

    // Espace presse

    $('.owl-pressSlider').owlCarousel({
        loop: true,
        nav: false,
        dots: false,
        smartSpeed: 1000,
        items: 4,
        margin: 15,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                dots: true,
            },
            600:{
                items:2,
                dots: true,
            },
            991:{
                items:4,
            }
        }
    });

    $('.owl-destroyedPressSlider').owlCarousel({
        loop: false,
        nav: false,
        dots: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        items: 4,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:true,
                mouseDrag: true,
                touchDrag: true,
                pullDrag: true,
                dots: true,
                // freeDrag: true
            },
            600:{
                items:2,
                nav:false,
                mouseDrag: true,
                touchDrag: true,
                pullDrag: true,
                dots: true
                // freeDrag: true
            },
            991:{
                items:4,
                nav:true,
                loop:false,
                dots: false,
                mouseDrag: false,
                touchDrag: false,
                pullDrag: false,
                // freeDrag: false
            }
        }
    });

    $('.owl-next').click(function () {
        $('.owl-pressSlider').trigger('next.owl.carousel');
    });

    $('.owl-prev').click(function () {
        $('.owl-pressSlider').trigger('prev.owl.carousel');
    });

    // Espace professionnels de santé

    $('.owl-sliderTools').owlCarousel({
        loop: true,
        nav: false,
        dots: false,
        smartSpeed: 1000,
        items: 3,
        margin: 15,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                dots: true,
            },
            768:{
                items:2,
                dots: true,
            },
            991:{
                items:3,
                dots: false,
            }
        }
    });

    $('.owl-destroyedSliderTools').owlCarousel({
        loop: false,
        nav: false,
        dots: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        items: 3,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                loop:true,
                dots: true,
                mouseDrag: true,
                touchDrag: true,
                pullDrag: true,
                // freeDrag: true,
            },
            600:{
                items:2,
                loop:true,
                dots: true,
                mouseDrag: true,
                touchDrag: true,
                pullDrag: true,
                // freeDrag: true,
            },
            991:{
                items:3,
                loop:false,
            }
        }
    });

    $('.owl-prev').click(function () {
        $('.owl-sliderTools').trigger('prev.owl.carousel');
    });

    $('.owl-next').click(function () {
        $('.owl-sliderTools').trigger('next.owl.carousel');
    });

    $('.nextTools').click(function() {
        $('.prevTools').addClass('active');
    })

    // FAQ

    $('h3.question').click(function() {
        $(this).parent().find('.text').slideToggle('slow');
        $(this).toggleClass('active');
    })

    // Bloc quote
    // console.log($('.blockQuote'));

    if ($('.blockQuote').length != 0) {
        var quote = $('.blockQuote').prev();
        if(quote.hasClass('blockForm')) {

            var height = $('.blockQuote').outerHeight()/2;
            var marginForm = $('.blockForm').css("margin-bottom");
            var heightQuote = parseInt(height) + parseInt(marginForm);

            $('.blockForm').css("padding-bottom", heightQuote);
            $('.blockQuote').css("margin-top", -heightQuote);
        }
    }

    if ($('.blockForm').length != 0 && $('.blockForm').hasClass('isLast')) {
        $('footer').css("margin-top", "0");
    }


});

function juizScrollTo(element){
	$(element).click(function(){
		var goscroll = false;
		var the_hash = $(this).attr("href");
		var regex = new RegExp("\#(.*)","gi");
		var the_element = '';

		if(the_hash.match("\#(.+)")) {
			the_hash = the_hash.replace(regex,"$1");

			if($("#"+the_hash).length>0) {
				the_element = "#" + the_hash;
				goscroll = true;
			}
			else if($("a[name=" + the_hash + "]").length>0) {
				the_element = "a[name=" + the_hash + "]";
				goscroll = true;
			}

			if(goscroll) {
				$('html, body').animate({
					scrollTop:$(the_element).offset().top - 100
				}, 'slow');
				return false;
			}
		}
	});
};
juizScrollTo('a[href^="#"]');
